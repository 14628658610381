import { FilterOperator, FilterType } from '@/utils/filter'
import { getFrontBaseUrl } from '@/utils/index'
import { UserModule } from '@/store/modules/user'
import router from './../router/index'

export const states = [
  'incomplete',
  'under_construction',
  'newly_build',
  'perfect_state',
  'good_state',
  'needs_renovation',
  'renovated',
  'partly_renovated'
]

export const assignationStates = [
  'active',
  'inactive',
  'offline',
  'withdrawn_from_owner',
  'under_negotiation',
  'under_offer_status',
  'sold',
  'sold_from_owner'
]

export const categories = [
  { key: 'residence', icon: 'el-icon-house' },
  { key: 'land', icon: 'el-icon-map-location' },
  { key: 'business', icon: 'el-icon-office-building' },
  { key: 'business_roof', icon: 'el-icon-office-building' }
]

export const types = [
  // residence
  { key: 'apartment', showOn: 'residence' },
  { key: 'bedsit', showOn: 'residence' },
  { key: 'maisonette', showOn: 'residence' },
  { key: 'detached_house', showOn: 'residence' },
  { key: 'villa', showOn: 'residence' },
  { key: 'loft', showOn: 'residence' },
  { key: 'bungalow', showOn: 'residence' },
  { key: 'building', showOn: 'residence' },
  { key: 'apartment_complex', showOn: 'residence' },
  { key: 'farm', showOn: 'residence' },
  { key: 'houseboat', showOn: 'residence' },
  { key: 'multi_level_apartment', showOn: 'residence' },
  // business_roof
  { key: 'office', showOn: 'business_roof' },
  { key: 'shop', showOn: 'business_roof' },
  { key: 'building', showOn: 'business_roof' },
  { key: 'warehouse', showOn: 'business_roof' },
  { key: 'industrial_area', showOn: 'business_roof' },
  { key: 'craft_space', showOn: 'business_roof' },
  { key: 'hotel', showOn: 'business_roof' },
  { key: 'business_building', showOn: 'business_roof' },
  { key: 'hall', showOn: 'business_roof' },
  { key: 'showroom', showOn: 'business_roof' },
  // business (land)
  { key: 'holding', showOn: 'land' },
  { key: 'parcel', showOn: 'land' },
  { key: 'parcel_within_zone', showOn: 'land' },
  { key: 'parcel_under_integration', showOn: 'land' },
  { key: 'island', showOn: 'land' },
  // business (general business)
  { key: 'parking', showOn: 'business' },
  { key: 'business', showOn: 'business' },
  { key: 'prefabricated', showOn: 'business' },
  { key: 'detachable', showOn: 'business' },
  { key: 'air', showOn: 'business' },
  { key: 'catering_industry', showOn: 'business' },
  { key: 'trade', showOn: 'business' },
  { key: 'services', showOn: 'business' },
  { key: 'other_business', showOn: 'business' },
  { key: 'other_real_estate', showOn: 'business' }
]

export const orientations = [
  'east',
  'east_west',
  'east_meridian',
  'north',
  'north_east',
  'north_west',
  'west',
  'west_meridian',
  'meridian',
  'south',
  'south_east',
  'south_west'
]

export const assignationTypes = [
  'exclusive',
  'simple'
]

export const availableForOptions = [
  'sale',
  'lease'
]

export const slopeOptions = [
  'plane',
  'inclining',
  'amphitheatric'
]

export const floorTypes = [
  'marble',
  'wood',
  'stone',
  'ceramic_tiles',
  'laminate',
  'mosaic_tiles',
  'wood_and_marble',
  'marble_and_tile',
  'wood_and_stone',
  'stone_and_marble',
  'wood_and_tile',
  'wood_and_mosaic',
  'industrial'
]

export const framingOptions = [
  'wooden',
  'aluminium',
  'synthetic'
]

export const zoningOptions = [
  'agricultural',
  'commercial',
  'industrial',
  'recreational',
  'residential',
  'unincorporated'
]

export const roadTypes = [
  'asphalt',
  'pedestrian',
  'paved',
  'dirt_road',
  'no_road_access',
  'sea',
  'other'
]

export const gardenTypes = [
  'private',
  'shared'
]

export const electricityTypes = [
  'industrial',
  'single_phase',
  'three_phase'
]

export const glassTypes = [
  'single',
  'double',
  'triple'
]

export const energyClasses = [
  'Α_PLUS',
  'A',
  'B_PLUS',
  'B',
  'G',
  'D',
  'E',
  'Z',
  'H',
  'exemption'
]

export const heatingOptions = [
  'central_oil',
  'central_gas',
  'independent_oil',
  'independent_gas',
  'individual_gas',
  'heat_accumulators',
  'air_condition',
  'heat_pump',
  'gas',
  'stove',
  'pellet',
  'infrared',
  'fan_coil',
  'wood',
  'teleheating',
  'geothermal_energy',
  'other',
  'none'
]

export const seaDistanceOptions = [
  'first_sea_apartment',
  'below_100_meters',
  'between_101_and_500_meters',
  'above_500_meters'
]

export const checks = [
  'furnished',
  'penthouse',
  'warehouse',
  'safety_door',
  'alarm',
  'elevator',
  'garden',
  'solar_water_heater',
  'parking',
  'veranda',
  'accessible_for_disabled',
  'air_conditioning',
  'fireplace',
  'cable_ready',
  'satellite_receiver',
  'short_term_letting',
  'exchange_scheme',
  'pool',
  'airy',
  'investment',
  'floor_apartment',
  'facade',
  'suspended_ceiling',
  'staircase',
  'floor_heating',
  'pets_allowed',
  'night_electricity',
  'tent',
  'screens',
  'bright',
  'painted',
  'double_glazing',
  'structural_wiring',
  'playroom',
  'boiler',
  'attic',
  'corner',
  'openness_view',
  'sea_view',
  'mountain_view',
  'park_view',
  'student',
  'neoclassic',
  'preserved',
  'luxury',
  'cottage'
]

export const professions = [
  'self_employed',
  'civil_servant',
  'businessman',
  'private_employee',
  'broker',
  'contractor',
  'engineer',
  'notary',
  'retired',
  'layer',
  'doctor'
]
export const foundFromList = [
  'bee_website',
  'xe',
  'spitogatos',
  'tospitimou',
  'spiti24',
  'social_networks',
  'internet',
  'aggelioforos',
  'newspaper_magazine',
  'banner',
  'newsletter',
  'office_advertising',
  'open_house',
  'office_visitor',
  'other_office_recommendation',
  'known_recommendation',
  'personal_contact'
]
export const propertyStatuses = [
  'in_process',
  'sent_for_approval',
  'approved',
  'changes_required',
  'sold_or_rented_by_office',
  'sold_or_rented_by_owner',
  'withdrawn_by_owner',
  'expired',
  'inactive',
  'needs_transfer'
]
export const services = [
  'spitogatos',
  'plot',
  'xe'
]

export const rooms = [{
  key: 'single_area',
  value: 0
}, {
  value: 2
}, {
  value: 3
}, {
  value: 4
}, {
  value: 5
}, {
  value: 6
}, {
  value: 7
}, {
  value: 8
}, {
  value: 9
}, {
  value: 10
}, {
  key: 'ten_plus',
  value: 11
}]

export const levels = [{
  value: 1
}, {
  value: 2
}, {
  value: 3
}, {
  value: 4
}, {
  value: 5
}, {
  value: 6
}, {
  value: 7
}, {
  key: 'eighth_and_above',
  value: 8
}]

export const bathrooms = [{
  value: 0
}, {
  value: 1
}, {
  value: 2
}, {
  value: 3
}, {
  value: 4
}, {
  key: 'fifth_and_above',
  value: 5
}]

export const toilets = [{
  value: 0
}, {
  value: 1
}, {
  value: 2
}, {
  value: 3
}, {
  value: 4
}, {
  key: 'fifth_and_above',
  value: 5
}]

export const floors = [
  {
    key: 'below_basement',
    value: -2
  },
  {
    key: 'basement',
    value: -1
  },
  {
    key: 'semibasement',
    value: -0.5
  },
  {
    key: 'ground_floor',
    value: 0
  },
  {
    key: 'mezzanine',
    value: 0.5
  },
  {
    key: 'first',
    value: 1
  },
  {
    key: 'second',
    value: 2
  },
  {
    key: 'third',
    value: 3
  },
  {
    key: 'fourth',
    value: 4
  },
  {
    key: 'fifth',
    value: 5
  },
  {
    key: 'sixth',
    value: 6
  },
  {
    key: 'seventh',
    value: 7
  },
  {
    key: 'eighth_and_above',
    value: 8
  }
]

export const mediaTypes = [
  'living_room',
  'kitchen',
  'room',
  'bathroom',
  'wc',
  'hall',
  'balcony',
  'view',
  'warehouse',
  'property_facade',
  'interior_view',
  'outdoor_view',
  'plot_front',
  'plot_area',
  'top_view',
  'opsi',
  'section',
  'topographic'
]

export const actions = [{
  title: 'update_collaborators_by_phone',
  type: 'datetime',
  value: null
}, {
  title: 'update_profs_by_phone',
  type: 'datetime',
  value: null
}, {
  title: 'update_customers_by_letter',
  type: 'datetime',
  value: null
}, {
  title: 'ad_brochures',
  type: 'datetime',
  value: null
}, {
  title: 'sale_announcement',
  type: 'datetime',
  value: null
}, {
  title: 'newsletter',
  type: 'datetime',
  value: null
}, {
  title: 'open_house',
  type: 'datetime',
  value: null
}, {
  title: 'update_neighbors_door_to_door',
  type: 'datetime',
  value: null
}, {
  title: 'banner',
  type: 'datetime',
  value: null
}, {
  title: 'neighbor_posters',
  type: 'datetime',
  value: null
}, {
  title: 'newspaper_post',
  type: 'datetime',
  value: null
}, {
  title: 'collaborator_notes',
  type: 'textarea',
  value: null
}]

export const residenceAttributes = [
  'furnished',
  'penthouse',
  'cable_ready',
  'accessible_for_disabled',
  'warehouse',
  'safety_door',
  'alarm',
  'elevator',
  'garden',
  'solar_water_heater',
  'parking',
  'veranda',
  'air_conditioning',
  'fireplace',
  'pool',
  'airy',
  'investment',
  'floor_apartment',
  'facade',
  'suspended_ceiling',
  'staircase',
  'floor_heating',
  'pets_allowed',
  'night_electricity',
  'tent',
  'screens',
  'bright',
  'painted',
  'double_glazing',
  'structural_wiring',
  'short_term_letting',
  'available_for_daily_rent',
  'satellite_receiver',
  'playroom',
  'boiler',
  'attic',
  'corner',
  'openness_view',
  'sea_view',
  'mountain_view',
  'park_view',
  'student',
  'neoclassic',
  'preserved',
  'luxury',
  'cottage',
  'manned_reception',
  'price_includes_power',
  'price_includes_water',
  'price_includes_internet',
  'price_includes_shared_exp',
  'electric_car_charging_facilities',
  'built_in_bbq',
  'leased',
  'cave_building',
  'within_zoning_plan',
  'fire_detectors',
  'fire_extinguishing_system',
  'number_of_entrances',
  'medical_office'
]

export const businessAttributes = [
  'warehouse',
  'safety_door',
  'alarm',
  'elevator',
  'garden',
  'solar_water_heater',
  'satellite_receiver',
  'cable_ready',
  'parking',
  'air_conditioning',
  'pool',
  'airy',
  'investment',
  'mild_sanitation',
  'funnel',
  'facade',
  'suspended_ceiling',
  'staircase',
  'floor_heating',
  'night_electricity',
  'with_equipment',
  'cargo_lift',
  'bright',
  'painted',
  'double_glazing',
  'structural_wiring',
  'unloading_ramp',
  'suitable_for_professional',
  'accessible_for_disabled',
  'boiler',
  'attic',
  'corner',
  'openness_view',
  'sea_view',
  'mountain_view',
  'park_view',
  'neoclassic',
  'preserved',
  'luxury',
  'manned_reception',
  'price_includes_power',
  'price_includes_water',
  'price_includes_internet',
  'price_includes_shared_exp',
  'electric_car_charging_facilities',
  'leased',
  'cave_building',
  'within_zoning_plan',
  'fire_detectors',
  'fire_extinguishing_system',
  'number_of_entrances',
  'medical_office'
]

export const landAttributes = [
  'agricultural_use',
  'exchange_scheme',
  'corner',
  'openness_view',
  'sea_view',
  'mountain_view',
  'park_view',
  'within_zoning_plan',
  'buildable'
]

/* eslint-disable camelcase */
export enum PropertyFloor {
  below_basement = -2,
  basement = -1,
  semibasement = -0.5,
  ground_floor = 0,
  mezzanine = 0.5,
  first = 1,
  second = 2,
  third = 3,
  fourth = 4,
  fifth = 5,
  sixth = 6,
  seventh = 7,
  eighth_and_above = 8,
}

export const getImagePreview = (property: any) => {
  if (!property.media.images.length) {
    return ''
  }
  return `/static/properties/${property.id}/images/480_${property.media.images[0].name}`
}

export const getFloorTitle = (row: any) => {
  if (row.floor === undefined || row.floor === null) return 'floor.eighth_and_above'
  if (row.floor >= 8) return 'floor.eighth_and_above'
  if (PropertyFloor[row.floor]) return 'floor.' + PropertyFloor[row.floor]
  return row.floor
}

export const getPublicAndOfflineRequiredFilters = (excludeOffline = false) => {
  let statuses = ['active', 'under_negotiation', 'under_offer_status', 'offline']
  if (excludeOffline) {
    statuses = statuses.filter(item => item !== 'offline')
  }
  return [{
    type: FilterType.field,
    key: 'status',
    value: 'approved',
    operator: FilterOperator.eq
  }, {
    type: FilterType.operator,
    operator: FilterOperator.or,
    items: statuses.map((key) => {
      return {
        type: FilterType.field,
        key: 'assignation_state',
        value: key,
        operator: FilterOperator.eq
      }
    })
  }]
}

export const prepareDashboardFilters = (listQuery: any, range: any, key = 'date_created') => {
  if (!listQuery.query?.filters?.items) return

  listQuery.query.filters.items = listQuery.query.filters.items.filter((item: any) => {
    return item.key !== key
  })

  if (range.from) {
    listQuery.query?.filters?.items.push({
      type: FilterType.field,
      value: range.from,
      operator: FilterOperator.ge,
      key
    })
  }

  if (range.to) {
    listQuery.query?.filters?.items.push({
      type: FilterType.field,
      value: range.to,
      operator: FilterOperator.le,
      key
    })
  }
}

export const isLatitudeValid = (latitude: number) => {
  return latitude && latitude >= -90 && latitude <= 90
}

export const isLongitudeValid = (longitude: number) => {
  return longitude && longitude >= -180 && longitude <= 180
}

export const redirectToSite = (property: any) => {
  return window.open(`${getFrontBaseUrl()}/custom-property?id=${btoa(JSON.stringify({
    id: property.id,
    user_id: UserModule.id
  }))}`, '_blank')
}

export const redirectToEdit = (property: any, newTab = false) => {
  const baseRoute = property.auction ? 'auction' : 'property'
  if (newTab) {
    const routeData = router.resolve({
      name: `Edit${baseRoute.charAt(0).toUpperCase() + baseRoute.slice(1)}`,
      params: { id: property.id }
    })
    return window.open(routeData.href, '_blank')
  }
  return router.push(`/${baseRoute}/edit/${property.id}`)
}
